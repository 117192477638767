import React, { useEffect, useState, useMemo } from 'react'
import { useMediaQuery } from "react-responsive";
import '../../src/Styles.css';
import HomeDrawer from '../Components/HomeDrawer';
import caribeLogo from '../assets/caribe-logo.png'
import latinoLogo from '../assets/latino-logo.png'


const AppsNav = () => {

    const minMax = useMediaQuery({ query: '(max-width: 900px)' })

    const [themeTextColor, setTheme] = useState(null);

    const isDark = undefined;

    const systemPrefersDark = useMediaQuery(
        {
            query: "(prefers-color-scheme: dark)",
        },
        undefined
    );

    const value = useMemo(
        () => (isDark === undefined ? !!systemPrefersDark : isDark),
        [isDark, systemPrefersDark]
    );

    useEffect(() => {
        if (value) {
            setTheme('#fff')
        } else {
            setTheme('#111')
        }
    }, [value]);

    return (
        <div >
            <div>
                <HomeDrawer />
            </div>

            <p className="home-logo" style={{ textAlign: 'center', fontSize: 30, marginTop: '20%', color: '#29abe2' }}>Yana Plus</p>

            <div >
                <div >
                    {minMax ? <h3 style={{ textAlign: 'center', fontSize: 28, color: themeTextColor, marginTop: '20%' }}>Choose an App</h3> : <h3 style={{ textAlign: 'center', fontSize: 30, color: themeTextColor, marginTop: '2%' }}>Choose an App</h3>}
                </div>

                <div className='not-found-center' >


                    {minMax ? <div onClick={() => window.location.href = 'https://africa-match.com/apps'} style={{ marginTop: 12 }}> <img src={latinoLogo} alt="Trulli" width="100" height="100"></img> </div> : <div onClick={() => window.location.href = 'https://africa-match.com/apps'} style={{ marginTop: 12 }}><img src={latinoLogo} alt="Trulli" width="150" height="150"></img> </div>}

                    {minMax ? <div onClick={() => window.location.href = 'https://caribematch.com/apps'}> <img src={caribeLogo} alt="Trulli" width="50" height="50"></img>    </div> : <div onClick={() => window.location.href = 'https://caribematch.com/apps'}><img src={caribeLogo} alt="Trulli" width="100" height="100"></img>  </div>}


                </div>

            </div>

        </div >
    )
}

export default AppsNav