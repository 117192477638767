
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";


import AboutUs from './Components/AboutUs'
import AppNav from './Components/AppNav'


//import HomeDrawer from './Components/HomeDrawer';
import PageNotFound from './Components/404NotFound';
import './Styles.css';


function App() {

  const isDark = undefined;
  // Detecting the default theme
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );


  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [value]);

  return (
    <div>

      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" exact element={<AboutUs />} />
            <Route path="apps" element={<AppNav />} />
            <Route element={<PageNotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;

// Filename - App.js

// import { useRef } from 'react';
// import { QRCodeSVG } from 'qrcode.react';
// import mockup from '../src/assets/logo192.png'


// function App() {

//   const qrRef = useRef(null);

//   const handleDownloadPNG = () => {
//     const svg = qrRef.current.querySelector('svg');
//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');
  
//     const svgData = new XMLSerializer().serializeToString(svg);
//     const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
//     const url = URL.createObjectURL(svgBlob);
  
//     const img = new Image();
//     img.onload = () => {
//       canvas.width = img.width;
//       canvas.height = img.height;
//       ctx.drawImage(img, 0, 0);
  
//       URL.revokeObjectURL(url);
  
//       canvas.toBlob((blob) => {
//         const link = document.createElement('a');
//         link.href = URL.createObjectURL(blob);
//         link.download = 'qrcode.png';
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       }, 'image/png');
//     };
//     img.src = url;
//   };
//   return (
//     <div className="App" style={{backgroundColor: '#000'}}>
//       <br />
//       <br />
//       <center>
//         <QRCodeSVG
//           value={"https://caribematch.com/apps"}
//           size={128}
//           bgColor={"black"}
//           fgColor={"white"}
//           level={"M"}
//           includeMargin={false}
//           imageSettings={{
//             src: "https://static.zpao.com/favicon.png",
//             x: undefined,
//             y: undefined,
//             height: 24,
//             width: 24,
//             excavate: true,
//           }}
//         />
//       </center>

//       <center>
//         <QRCodeSVG
//           value={"https://caribematch.com/apps"}
//           size={128}
//           bgColor={"black"}
//           fgColor={"#4eb74c"}
//           level={"M"}
//           includeMargin={false}
//           imageSettings={{
//             src: "https://static.zpao.com/favicon.png",
//             x: undefined,
//             y: undefined,
//             height: 24,
//             width: 24,
//             excavate: true,
//           }}
//         />
//       </center>

//       <center>
//         <QRCodeSVG
//           value={"https://caribematch.com/apps"}
//           size={128}
//           bgColor={"black"}
//           fgColor={"#00b5b5"}
//           level={"M"}
//           includeMargin={false}
//           imageSettings={{
//             src: "https://static.zpao.com/favicon.png",
//             x: undefined,
//             y: undefined,
//             height: 24,
//             width: 24,
//             excavate: true,
//           }}
//         />
//       </center>

//       <div ref={qrRef}>
//         <QRCodeSVG
//           value={"https://caribematch.com/apps"}
//           size={128}
//           bgColor={"black"}
//           fgColor={"#00b5b5"}
//           level={"M"}
//           includeMargin={false}
//           // imageSettings={{
//           //   src: mockup,
//           //   x: undefined,
//           //   y: undefined,
//           //   height: 24,
//           //   width: 24,
//           //   excavate: true,
//           // }}
//         />
//       </div>
// <button onClick={handleDownloadPNG}>Download QR Code as PNG</button>
//     </div>
//   );
// }

// export default App;
