
//import "react-responsive-carousel/lib/styles/carousel.min.css";
//import { Carousel } from 'react-responsive-carousel';
import React, { useEffect, useState, useMemo } from 'react';
import '../../src/Styles.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import HomeDrawer from '../Components/HomeDrawer';
import caribeLogo from '../assets/caribe-logo.png'
import latinoLogo from '../assets/latino-logo.png'


const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [themeTextColor, setTheme] = useState(null);

  const isDark = undefined;
  const minMax = useMediaQuery({ query: '(max-width: 900px)' })

  // Detecting the default theme
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );

  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      setTheme('#fff')
    } else {
      setTheme('#111')
    }
  }, [value]);


  function getMobileOperatingSystem() {

    // Get the user agent string from the browser
    const userAgent = navigator.userAgent;

    // Check if the user agent string contains keywords for different operating systems
    if (userAgent.match(/Windows/i)) {
      window.open('https://play.google.com/store/apps/details?id=com.uyana.scripts.app.gy', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/Macintosh|Mac OS/i)) {
      window.open('https://apps.apple.com/us/app/caribe-match-caribbean-dating/id1665001650', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/Android/i)) {
      window.open('https://play.google.com/store/apps/details?id=com.uyana.scripts.app.gy', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/iOS/i)) {
      window.open('https://apps.apple.com/us/app/caribe-match-caribbean-dating/id1665001650', "_blank", "noreferrer");
      return null;
    } else {
      return null
    }
  }

  function getMobileOperatingSystem1() {

    // Get the user agent string from the browser
    const userAgent = navigator.userAgent;

    // Check if the user agent string contains keywords for different operating systems
    if (userAgent.match(/Windows/i)) {
      window.open('https://play.google.com/store/apps/details?id=com.latino.date.raguay.app', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/Macintosh|Mac OS/i)) {
      window.open('https://apps.apple.com/us/app/africa-match-african-dating/id6740301287', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/Android/i)) {
      window.open('https://play.google.com/store/apps/details?id=com.latino.date.raguay.app', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/iOS/i)) {
      window.open('https://apps.apple.com/us/app/africa-match-african-dating/id6740301287', "_blank", "noreferrer");
      return null;
    } else {
      return null
    }
  }

  return (

    <div className='blog-div-container'>
      <div>
        <HomeDrawer />
      </div>

      <div className='blog-content'>

        <div onClick={() => window.location.href = 'https://caribematch.com/'} style={{ textDecoration: 'none', cursor: 'pointer' }} className="App-link">
          <div className="logo" ><h1 style={{ color: themeTextColor, }}>Yana Plus</h1></div>
        </div>

        <h2 className='blog-pargraph-title' style={{ color: themeTextColor }}>About us</h2>

        <div >

          <div >
            <p className='blog-pargraph' style={{ color: themeTextColor }} >
              Welcome to Yana Plus. The community where single West Indians and African's meet:
              Whether in Trinidad and Tobago, Jamaica, Guyana, Barbados, Cuba, Saint Lucia,
              Haiti, the Dominican Republic, the Bahamas, Nigeria, South Africa, Kenya, Ghana,
              Ethiopia, Egypt, Tanzania, Morocco, Algeria, or beyond, Caribe Match & Africa Match connects you with
              locals across our beautiful nations!
            </p>
          </div>

          <div className='about-pargraph1'>
            <p className='blog-pargraph' style={{ color: themeTextColor }}>
              Caribe Match & Africa Match are dating app for singles in the Caribbean
              and Africa America owned and operated by Yana Plus LLC. With Caribe
              Match & Africa Match, you can find serious relationships,
              meet new friends, date, and make meaningful connections. We’ve
              created an exciting online dating environment for those ready to
              leap into the dating world. Our features are designed to help you
              build a relationship that goes beyond just being friends.
            </p>
          </div>

          <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor }}> Our mission</h2>

          <div >
            <p className='blog-pargraph' style={{ color: themeTextColor }}>
              Our mission is simple: To help singles in the Caribbean & Africa find love with meaningful
              connections through online dating.
            </p>
          </div>


          <div className='about-pargraph1'>
            <p className='blog-pargraph' style={{ color: themeTextColor }}>
              Join the thousands of single West Indians and African's finding their partner
              in a fun and easy way—download Caribe Match & Africa Match and enjoy the best
              online dating app today!
            </p>
          </div>

          <div className='footer' style={{ marginTop: '3%' }}>

            <h2 className='blog-pargraph-sub-title' style={{ color: themeTextColor }}> Our Brands</h2>

            <div className='home-android-ios' >

              <div onClick={getMobileOperatingSystem}>

                {minMax ? <img src={caribeLogo} alt="Trulli" width="50" height="50"></img> : <img src={caribeLogo} alt="Trulli" width="100" height="100"></img>}

              </div>

              <div onClick={getMobileOperatingSystem1} style={{ marginTop: 12 }}>

                {minMax ? <img src={latinoLogo} alt="Trulli" width="100" height="100"></img> : <img src={latinoLogo} alt="Trulli" width="150" height="150"></img>}

              </div>


            </div>

            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://yana-gy.com/'}>About us</Link>
            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://yana-gy.com/'}> | </Link>


            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://caribematch.com/dating-tips'}>Dating  Tips</Link>
            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://caribematch.com/dating-tips'}> | </Link>

            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://caribematch.com/safety-tips'}>Dating  Safety  Tips</Link>
            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://caribematch.com/safety-tips'}> | </Link>

            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://caribematch.com/privacy-policy'}>Privacy  policy</Link>
            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://caribematch.com/privacy-policy'}> | </Link>

            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://caribematch.com/terms-conditions'}>Terms  &  Conditions</Link>
            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://caribematch.com/terms-conditions'}> | </Link>

            <Link className='pargrah-font' style={{ textDecoration: 'none', margin: '1%', color: themeTextColor, }} onClick={() => window.location.href = 'https://caribematch.com/contact-us'}> Support</Link>

          </div>

          <div className='copy-right'>
            <p className='pargrah-font' style={{ color: themeTextColor }}>@2025 Yana Plus LLC.</p>
          </div>

        </div>
      </div>
    </div>

  );
}

export default AboutUs;
